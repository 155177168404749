<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "ItsOkayToBeEditedReally",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="ItsOkayToBeEditedReally">
      <SuawParagraph
        text="This isn’t what I expected. There are the horror stories of plot changes, severe cuts, title changes, red ink splashing across the electronic page. Working with a book editor is supposed to be a soul-crushing, ego-blasting, albeit improving, experience."
      />
      <SuawParagraph text="Instead, it was sort of fun." />
      <SuawParagraph
        text="“Crime and Paradise” is my first book, a suspense/mystery that I wrote in about nine months and rewrote, and rewrote once more, over the course of another five months. My characters haunted my dreams and talked to me at odd moments during the day. I was ready to cut it loose. The manuscript was contracted by a publisher fairly quickly."
      />
      <SuawParagraph text="Maybe it was the first line: “If you’re married, you need to know this: your spouse has thought about killing you.”" />
      <SuawParagraph
        text="It didn’t take long for self-doubt to creep in. Even though a publisher wanted it and was willing to pay its crew of professionals – a content editor, copy editor, cover artist, marketing folks and others – to shepherd it through the process, I wondered if it was really good enough."
      />
      <SuawParagraph
        text="The words of the contract came back to me, indicating the publisher owned the rights now. What if I yoked my book to a tyrant? What if they wanted to change everything?"
      />
      <SuawParagraph text="I both dreaded and looked forward to the first step of being assigned a professional editor." />
      <SuawParagraph
        text="Two opposing thoughts went through my mind simultaneously: little editing would be required; also, the editor would tear my book apart. Hours, days, weeks crawled by. The longer I waited, the more dread I felt."
      />
      <SuawParagraph
        text="The email dinged into my inbox while I was attending the San Francisco Writers Conference in February, coincidentally listening to a panel of book editors discuss mistakes new writers make. Their list went on and on, increasing my fears. My throat went dry. I closed the email unread."
      />
      <SuawParagraph
        text="I’d hate for my editor to know this, but I couldn’t open her edits for two more days. Visions of red ink splotched across pages puddled in my brain. I had a few weeks to go through this first round of edits and time was slipping by."
      />
      <SuawParagraph text="A word about the process here:" />
      <SuawParagraph
        text="My editor’s name is Rachel. She’s on the East Coast while I’m in Boise, Idaho so, while we’ve never met in person, she’s very friendly via email. She let me know up front my book would go through a few rounds of editing and that she was excited to be working with me on this book. We had a few months to work together on the manuscript before it would be turned over to a copy editor to look over grammar, punctuation, etc. It would run through another editor at the end, who would read it through once more as a final check. There would be final galleys to review as well."
      />
      <SuawParagraph text="All this made me more confident the manuscript would be at its very best by the time it was published." />
      <SuawParagraph
        text="A friend once said he didn’t want to work with an editor because he didn’t want someone else telling him how to write his book. I thought about this philosophy a bit while my finger hovered over my edited document, ready to open it."
      />
      <SuawParagraph text="Finally, I dove in. Yeah, she used red ink." />
      <SuawParagraph
        text="Here’s the thing: She was right about the edits. The further along the process I went, the more excited I became. Seriously. My manuscript was getting stronger. She noted words I repeated, places where I may have come on a little strong, places that were weak, phrasing that didn’t quite work. She left it up to me to make the changes, along with a note saying if I disagreed with anything to let her know and we could discuss."
      />
      <SuawParagraph
        text="There were one or two places I really wanted to remain the same. We discussed and in one place, she convinced me it just didn’t work; in the other, she said it could stay the same. Mostly, I looked at her edits and felt like slapping myself on the forehead, as in “duh, why did I do that?”"
      />
      <SuawParagraph
        text="Being an avid reader – immersing myself in literature, studying character development, motivations and descriptions – is nothing like writing a book. That’s akin to saying watching sports is all you need to be an athlete. You have to get out and do it."
      />
      <SuawParagraph
        text="Writing takes place an hour at a time, in front of my laptop, my fingers tapping on the keyboard. I study up more these days on the craft of writing, but ultimately, the work gets done only when I write."
      />
      <SuawParagraph
        text="So, it didn’t come as a surprise my manuscript required some coaching. The process was rewarding; it made me a better writer. “Crime and Paradise” is the first in a trilogy and I was writing the second book while going through the editing process on the first. I became more aware of the mistakes I routinely make and hopefully avoided many of them while writing the second one."
      />
      <SuawParagraph
        text="No book is perfect. Even the best writers need editors. My philosophy is that as a new author, I don’t know anything. An author and editor both have the same goal: to make your book the best it can be."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
